import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import settingsParams from '../../components/BookOnline/settingsParams';
import { FilterOption, FilterServicesByOptions } from '../../types/types';
import { BookingsAPI } from '../../api/BookingsApi';
import {
  BookingsQueryParams,
  getUrlQueryParamValue,
} from '@wix/bookings-catalog-calendar-viewer-utils';
import {
  ALL_SERVICES,
  REQUESTED_CATEGORIES_URL_PARAM_NAME,
} from '../../consts';
import { ReservedLocationIds } from '@wix/bookings-uou-types';
import { ServiceListSettings } from '../../../legacy/appSettings/appSettings';
import {
  LocationType,
  V2Category,
} from '@wix/ambassador-bookings-services-v2-service/types';

export async function getFilterOptions({
  flowAPI,
  bookingsApi,
  appSettings,
}: {
  flowAPI: ControllerFlowAPI;
  bookingsApi: BookingsAPI;
  appSettings?: ServiceListSettings;
}): Promise<FilterOption[]> {
  const {
    settings,
    translations: { t },
  } = flowAPI;

  if (!isListFilterVisible({ flowAPI, appSettings })) {
    return [];
  }

  const filterServicesBy = appSettings
    ? appSettings.CATEGORIES_TYPE
    : settings.get(settingsParams.filterServicesBy);

  const filterOptions =
    filterServicesBy === FilterServicesByOptions.LOCATIONS
      ? await bookingsApi
          .fetchLocationTabs()
          .then(({ locationTypes, businessLocations }) => {
            const locations =
              businessLocations?.sort(sortLocations).map((location) => ({
                id: location.id,
                title: location.name,
                isSelected: false,
              })) ?? [];
            const hasOtherLocations = locationTypes?.find(
              (locationType) => locationType !== LocationType.BUSINESS,
            );

            return hasOtherLocations
              ? [
                  ...locations,
                  {
                    id: ReservedLocationIds.OTHER_LOCATIONS,
                    title: t(
                      'service-list.categories.location-categories.other-locations',
                    ),
                    isSelected: false,
                  },
                ]
              : locations;
          })
          .catch((error) => {
            console.error(error);
            return [];
          })
      : await bookingsApi
          .fetchCategories()
          .then(
            (unsortedCategories) =>
              unsortedCategories?.sort(sortCategories).map((category) => ({
                id: category.id,
                title: category.name,
                isSelected: false,
              })) ?? [],
          )
          .catch((error) => {
            console.error(error);
            return [];
          });

  if (
    isShowAllServicesFilterOptionVisible({
      appSettings,
      flowAPI,
      filterOptions,
    })
  ) {
    filterOptions.unshift({
      id: ALL_SERVICES,
      title:
        (appSettings
          ? (appSettings.CATEGORY_ALL_SERVICES_TEXT as string)
          : settings.get(settingsParams.allServices)) ||
        t('category-name.all-services'),
      isSelected: true,
    });
  } else if (filterOptions.length > 0) {
    filterOptions[0].isSelected = true;
  }

  const preSelectedCategory = getUrlQueryParamValue(
    flowAPI.controllerConfig.wixCodeApi,
    REQUESTED_CATEGORIES_URL_PARAM_NAME as BookingsQueryParams,
  );

  const hasCategoryDeepLink = !!preSelectedCategory;

  const preSelectedLocation = getUrlQueryParamValue(
    flowAPI.controllerConfig.wixCodeApi,
    BookingsQueryParams.LOCATION,
  );

  const hasLocationDeepLink = !!preSelectedLocation;

  if (
    hasCategoryDeepLink &&
    filterServicesBy === FilterServicesByOptions.CATEGORIES
  ) {
    choosePreselectedFilter(filterOptions, preSelectedCategory);
  } else if (
    hasLocationDeepLink &&
    filterServicesBy === FilterServicesByOptions.LOCATIONS
  ) {
    choosePreselectedFilter(filterOptions, preSelectedLocation);
  }

  return filterOptions;
}

const choosePreselectedFilter = (
  filterOptions: FilterOption[],
  preSelectedFilterId: string,
) => {
  filterOptions.forEach((filterOption) => {
    filterOption.isSelected = filterOption.id === preSelectedFilterId;
  });
  if (
    filterOptions.every((filterOption) => !filterOption.isSelected) &&
    filterOptions.length > 0
  ) {
    filterOptions[0].isSelected = true;
  }
};

const sortLocations = (
  location1: { name: string; id: string },
  location2: { name: string; id: string },
) => {
  if (location1.id === ReservedLocationIds.OTHER_LOCATIONS) {
    return 1;
  }
  if (location2.id === ReservedLocationIds.OTHER_LOCATIONS) {
    return -1;
  }
  return location1.name > location2.name ? 1 : -1;
};

const sortCategories = (category1: V2Category, category2: V2Category) =>
  category1?.sortOrder! - category2?.sortOrder!;

export const isShowAllServicesFilterOptionVisible = ({
  appSettings,
  flowAPI,
  filterOptions,
}: {
  appSettings?: ServiceListSettings;
  flowAPI: ControllerFlowAPI;
  filterOptions?: FilterOption[];
}): boolean =>
  ((appSettings
    ? !!appSettings.CATEGORY_ALL_SERVICES_SHOW
    : flowAPI.settings.get(settingsParams.showAllServicesFilterOption)) ||
    flowAPI.experiments.enabled(
      'specs.bookings.disableShowAllServicesFilterOption',
    )) &&
  (filterOptions ? filterOptions.length > 1 : true);

export const isListFilterVisible = ({
  appSettings,
  flowAPI,
}: {
  appSettings?: ServiceListSettings;
  flowAPI: ControllerFlowAPI;
}): boolean =>
  appSettings
    ? !!appSettings.DISPLAY_CATEGORIES
    : flowAPI.settings.get(settingsParams.isListFilterVisible);
